// extracted by mini-css-extract-plugin
export var author = "resource_post-module--author--9bd49";
export var backButton = "resource_post-module--backButton--ffc95";
export var breadcrumbs = "resource_post-module--breadcrumbs--d7b69";
export var categoryButton = "resource_post-module--categoryButton--3de64";
export var contentContainer = "resource_post-module--contentContainer--8702c";
export var date = "resource_post-module--date--a2253";
export var divider = "resource_post-module--divider--04159";
export var postContent = "resource_post-module--postContent--25699";
export var postDetails = "resource_post-module--postDetails--e0df7";
export var sidebar = "resource_post-module--sidebar--ab01c";
export var sidebarContainer = "resource_post-module--sidebarContainer--a0edd";
export var sliceContainer = "resource_post-module--sliceContainer--09209";