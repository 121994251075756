import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import {
  Layout,
  BlogSlice,
  Section,
  Link,
  ResourcePostHeader,
  BlogPostSidebar,
  PostGrid,
  CategoryButtons,
} from 'components';
import * as styles from './resource_post.module.scss';

const ResourcePost = ({ location, data }) => {
  const {
    blogPost,
    relatedPosts: { nodes: relatedData },
    categoryList: { nodes: categories = {} },
    blogPostSidebarData,
    prismicResourceLandingPage: {
      data: { category_grid_theme: categoryGridTheme },
    },
  } = data;

  const { data: pageData, tags: postTags } = blogPost;
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    author,
    category,
    source,
    date,
    title,
    excerpt,
    featured_image: image,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
  };

  const postDetails = {
    author,
    category,
    source,
    excerpt,
    date,
    title,
    image,
  };

  const document = category?.document;
  const categoryUid = document?.uid;
  const categoryName = document?.data?.category_name;

  const authorName = author?.document?.data?.author_name;

  // populate related posts based firstly on tags, then category
  const relatedPostsArray = [];
  postTags.forEach((item) => {
    const tagPosts = relatedData.filter((post) => post?.tags?.includes(item));
    relatedPostsArray.push(...tagPosts);
  });
  // add category posts if not enough tagged posts
  if (relatedPostsArray.length < 3) {
    const categoryPosts = relatedData.filter((post) => post?.data?.category?.document?.uid === categoryUid);
    relatedPostsArray.push(...categoryPosts);
  }

  const relatedPosts = [...new Set(relatedPostsArray)].slice(0, 3);

  return (
    <Layout location={location} seo={seo} theme="light">
      <ResourcePostHeader {...postDetails} />
      <Section className={styles.postContent} containerClassName={styles.contentContainer} largeContainer>
        <div className={styles.sliceContainer}>
          <div className={styles.postDetails}>
            <div className={styles.breadcrumbs}>
              <Link to="/resources/" className={styles.backButton}>
                <span>Back to resources</span>
              </Link>
              {categoryName?.text && <span className={styles.divider}>/</span>}
              <Link to={categoryUid} className={styles.categoryButton}>
                <span>{categoryName?.text}</span>
              </Link>
            </div>
            <div>
              {date && <span className={styles.date}>Posted {date} </span>}
              {authorName?.text && <span className={styles.author}>by {authorName.text}</span>}
            </div>
          </div>
          {sliceData.map((slice) => (
            <BlogSlice key={slice.id} data={slice} location={location} />
          ))}
        </div>
        <div className={styles.sidebarContainer}>
          <BlogPostSidebar className={styles.sidebar} posts={relatedPosts} data={blogPostSidebarData} sidebar />
        </div>
      </Section>
      <Section className={styles.postContainer} largeContainer>
        <PostGrid
          posts={relatedPosts}
          title={categoryName?.text}
          categoryLink={`/resources/${categoryUid}/`}
          categoryLinkText="View all"
          largeContainer
        />
      </Section>
      <CategoryButtons categories={categories} categoryGridTheme={categoryGridTheme} />
    </Layout>
  );
};

export default withPrismicPreview(ResourcePost);

export const blogPostQuery = graphql`
  query BlogPostBySlug($uid: String!, $lang: String!) {
    blogPost: prismicResourcePost(uid: { eq: $uid }, lang: { eq: $lang }) {
      id
      uid
      _previewable
      prismicId
      tags
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        author {
          document {
            ... on PrismicResourceAuthor {
              id
              data {
                author_name {
                  html
                  text
                }
              }
            }
          }
        }
        category {
          document {
            ... on PrismicResourceCategory {
              id
              data {
                category_name {
                  html
                  text
                }
              }
              uid
            }
          }
        }
        title {
          html
          text
        }
        source {
          html
          text
        }
        excerpt {
          html
          text
        }
        date(formatString: "MMM DD YYYY")
        featured_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 2000, placeholder: BLURRED)
            }
          }
        }
        body {
          ...ResourceWysiwyg
          ...ResourcePostImage
          ...ResourceDownloadForm
          ...ResourceVideo
        }
      }
    }
    relatedPosts: allPrismicResourcePost(
      sort: { fields: data___date, order: DESC }
      filter: { uid: { ne: $uid }, lang: { eq: $lang } }
    ) {
      nodes {
        uid
        tags
        data {
          alternative_link {
            url
          }
          author {
            url
            document {
              ... on PrismicResourceAuthor {
                id
                data {
                  author_name {
                    text
                    html
                  }
                }
              }
            }
          }
          category {
            document {
              ... on PrismicResourceCategory {
                id
                data {
                  category_name {
                    html
                    text
                  }
                }
                uid
              }
            }
          }
          featured_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: CONSTRAINED, width: 382, height: 214, placeholder: BLURRED)
              }
            }
          }
          is_featured
          title {
            html
            text
          }
          date(formatString: "MMM DD YYYY")
        }
      }
    }
    categoryList: allPrismicResourceCategory(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          category_name {
            html
            text
          }
        }
        uid
      }
    }
    blogPostSidebarData: prismicResourceLandingPage(lang: { eq: $lang }) {
      data {
        resource_post_cta_content {
          html
          text
        }
        resource_post_cta_link {
          url
        }
        resource_post_cta_text {
          html
          text
        }
        resource_post_cta_title {
          html
          text
        }
      }
    }
    prismicResourceLandingPage(lang: { eq: $lang }) {
      data {
        category_grid_theme
      }
    }
  }
`;
